import request from "@/service/";



export const getAllPermissionList = () => {
    return request.get(
        `${window.VUE_APP_URL}/api/permissions/permissionList`,
    );
}





