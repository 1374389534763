import request from "@/service/";


export const addRole = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/roles`,
        data
    );
}

export const updateRole = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/roles/${data.id}`,
        data
    );
}
export const saveRolePermissions = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/roles/${data.roleId}/saveRolePermissions`,
        data
    );
}


export const getRoleById = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/roles/${id}`,
    );
}

/***
 *
 * @param params
 * @returns {*}
 */
export const getRoleList = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/roles`,
        {
            params: params
        }
    );
}

export const deleteRoleById = (id) => {
    return request.delete(
        `${window.VUE_APP_URL}/api/roles/${id}`,
    );
}




