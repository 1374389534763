import { render, staticRenderFns } from "./rolePermissionAssign.vue?vue&type=template&id=71fd3a77&scoped=true&"
import script from "./rolePermissionAssign.vue?vue&type=script&lang=js&"
export * from "./rolePermissionAssign.vue?vue&type=script&lang=js&"
import style0 from "./rolePermissionAssign.vue?vue&type=style&index=0&id=71fd3a77&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71fd3a77",
  null
  
)

export default component.exports