<template>
  <div class="role-list-container" id="page">
    <DsnPanel>
      <!-- <div slot="header" class="title clearfix">
        <el-form
            :inline="true"
            :model="queryParam"
            ref="roleQueryForm"
            :rules="{}"
            @submit.native.prevent
        >
          <el-form-item :label="`${this.$t('roles.fields.roleCode')}:`" prop="roleCode" size="medium">
            <dsn-input v-model="queryParam.roleCode" size="medium"
                       :placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('roles.fields.roleCode')}`"></dsn-input>
          </el-form-item>
          <el-form-item :label="`${this.$t('roles.fields.roleName')}:`" prop="roleName" size="medium">
            <dsn-input v-model="queryParam.roleName" size="medium"
                       :placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('roles.fields.roleName')}`"></dsn-input>
          </el-form-item>
          <el-form-item>
            <dsn-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleQuery(['roleQueryForm'])"
            >{{ $t('commbtn.query') }}
            </dsn-button>
            <dsn-button
                size="medium"
                type
                plain
                icon="el-icon-refresh"
                class="reset-button"
                @click="handleReset(['roleQueryForm'])"
            >{{ $t('commbtn.reset') }}
            </dsn-button>
          </el-form-item>
        </el-form>
      </div> flex-wrap: wrap;-->
	  
	  
	  <!-- @click="handleRoleAdd" -->
	   <!-- @click="handleEdit" -->
	  
	  
	  
	  <div style="display: flex;justify-content: flex-start;">
		  
		  <div style="margin: 0 10px 0 0;width:25vw;">
		  		  <div class="operation">
		  		    <dsn-button
		  		        size="medium"
		  		        type="primary"
		  		        icon="el-icon-edit"
		  		        style="margin-left:0"
		  		        @click="addrole"
						
		  		  			v-if="isAddBtn == true"
		  		    >{{ this.$t('commbtn.addRole') }}
		  		    </dsn-button>
		  		    <dsn-button
		  		        size="medium"
		  		        type="primary"
		  		        icon="el-icon-edit"
		  		        :disabled="selectionList.length !== 1"
		  		        @click="editrole"
		  		  			v-if="isEditBtn == true"
		  		        style="margin-left:0"
		  		    >{{ this.$t('commbtn.edit') }}
		  		    </dsn-button>
		  		    <dsn-button
		  		        size="medium"
		  		        type="danger"
		  		        icon="el-icon-delete"
		  		        style="margin-left:0"
		  		        :disabled="selectionList.length <= 0"
		  		        @click="handleDeleteCheck"
		  		  			v-if="isDelBtn == true"
		  		    >{{ this.$t('commbtn.delete') }}
		  		    </dsn-button>
		  		  </div>
				  <!-- :highlightCurrentRow="true" -->
		  		  <dsn-table
		  		      @row-click="selectCheckBox"
		  		      ref="multipleTable"
		  		      :data="pagingConfig.tableData"
		  		      v-loading="pagingConfig.tableLoading"
		  		      tooltip-effect="dark"
		  		      style="width: 100%;"
					  :stripe='false'
					  highlight-current-row
					  :row-class-name="tablerowStyle"
		  		      @selection-change="handleSelectionChange"
		  		  >
		  		    <el-table-column type="selection" width="55" align="center"/>
		  		    <!--        <el-table-column type="index" label="序号"/>-->
		  		    <!-- <el-table-column prop="roleCode" :label="this.$t('roles.fields.roleCode')" align="center" width="200px"/> -->
		  		    <el-table-column prop="roleName" :label="this.$t('roles.fields.roleName')" align="center" width="150px"/>
		  		    <el-table-column prop="defaultRoleFlag" :label="this.$t('roles.fields.defaultRole')" align="center" width="100px">
		  		                <template scope="scope">
		  		                  {{ scope.row.defaultRoleFlag === 1 ? '√' : '' }}
		  		                </template>
		  		    </el-table-column>
		  		    <el-table-column prop="remark" :label="this.$t('roles.fields.remark')" align="center" width="150px"/>
		  		    <!--        <el-table-column prop="sex" :label="this.$t('users.fields.sex')" align="center">-->
		  		    <!--          <template scope="scope">-->
		  		    <!--            {{ sexFormatter(scope.row.sex) }}-->
		  		    <!--          </template>-->
		  		    <!--        </el-table-column>-->
		  		    <!--        <el-table-column prop="workNo" :label="this.$t('users.fields.workNo')" align="right"/>-->
		  		    <!--        <el-table-column prop="email" :label="this.$t('users.fields.email')" align="center"/>-->
		  		    <!--        <el-table-column prop="phone" :label="this.$t('users.fields.phone')" align="center"/>-->
		  			
		  		    <!-- <el-table-column
		  		        fixed="right"
		  		        label="操作"
		  		    >
		  		      <template slot-scope="scope">
		  		        <el-button @click="handleRowRoleEdit(true,scope)"v-if="isEditBtn == true" type="text" size="medium">编辑</el-button>
		  		        <el-button @click="assignRolePermission(scope)"v-if="isEditBtn == true" type="text" size="medium">角色授权</el-button>
		  		        <el-button type="text" size="medium"   @click="assignRoleToUsers(scope)"v-if="isEditBtn == true">分配用户</el-button>
		  		      </template>
		  		    </el-table-column> -->
					
					
					
					
					
		  		  </dsn-table>
		  		  
		  		  <!-- <DsnFooter> -->
		  		    <dsn-pagination
		  		        v-if="showPage"
		  		        background
		  		        layout="->,total,prev,pager,next,sizes"
		  		        :total="pagingConfig.total"
		  		        :page-size="pagingConfig.pageSize"
		  		        :page-sizes="[5, 10, 15, 20]"
		  		        :current-page="pagingConfig.current"
		  		        @size-change="handlePageSizeChange"
		  		        @current-change="handleCurrentChange"
		  		    ></dsn-pagination>
		  		  <!-- </DsnFooter> -->
		  </div>
		  
		  <div style="margin: 0 10px 0 0;width:25vw;">
			  <div class="operation">
			    <dsn-button
			        size="medium"
			        type="primary"
			        style="margin-left:0"
					disabled
			        @click="handleRoleAdd"
			  			v-if="isAddBtn == true"
			    >当前角色权限
			    </dsn-button>
			  </div>
		  	<vxe-table
		  	    border
		  	    :scroll-y="{enabled: false}"
		  	    :span-method="rowspanMethod"
				resizable
		  	    :loading="tableLoading2"
		  		:header-cell-style="{'font-size':'17px','background':'#4e81bd','font-family':'微软雅黑','font-weight':'normal','color':'#ffffff','border':'1px #e8eaec solid','padding':'7px !important'}"
		  		max-height="700"
		  	    :data="flatList.filter(v => v.isshow == true)">
		  	  <vxe-table-column field="permissionName1" title="模块" width="130">
		  	    <template v-slot="{ row }">
		  	      <vxe-checkbox v-model="row.check1" disabled @change="check1ChangeEvent(row, row.check1)">{{
		  	          row.permissionName1
		  	        }}
		  	      </vxe-checkbox>
		  	    </template>
		  	  </vxe-table-column>
		  	  <vxe-table-column field="permissionName2" title="功能" width="180">
		  	    <template v-slot="{ row }">
		  	      <vxe-checkbox v-model="row.check2" disabled @change="check2ChangeEvent(row, row.check2)" v-if="row.isshow == true">{{
		  	          row.permissionName2
		  	        }}
		  	      </vxe-checkbox>
		  	    </template>
		  	  </vxe-table-column>
		  	  <vxe-table-column field="permissionName3" title="权限" width="140">
		  	    <template v-slot="{ row }">
		  	      <vxe-checkbox v-model="row.check3" disabled v-if="row.permissionName3!=null && row.isshow == true" @change="check3ChangeEvent(row, row.check3)">{{ row.permissionName3 }}
		  	      </vxe-checkbox>
		  	    </template>
		  	  </vxe-table-column>
		  	</vxe-table>
		  </div>
		  
		  <div style="width: 33vw;">
			  <div class="operation">
			    <dsn-button
			        size="medium"
			        type="primary"
			        style="margin-left:0"
					disabled
			        @click="handleRoleAdd"
			  			v-if="isAddBtn == true"
			    >当前分配用户
			    </dsn-button>
			  </div>
			  
			  
		  		  <dsn-table
		  		      ref="multipleTable2"
		  		      :data="pagingConfig2.tableData"
		  		      v-loading="pagingConfig2.tableLoading"
		  		      tooltip-effect="dark"
		  		      style="width: 100%;"
		  		  >
		  		    <el-table-column
		  		    	width="100"
		  		    	type="index"
		  		    	align="center"
		  		    	:label="$t('iework.partshead[0]')"
		  		    >
		  		    	<template slot-scope="scope">
		  		    		<span>{{(pagingConfig2.current-1)*pagingConfig2.pageSize+scope.$index+1}}</span>
		  		    	</template>
		  		    </el-table-column>
		  		    
		  		    <el-table-column prop="realname" :label="this.$t('users.fields.realname')" align="center" width="150"/>
		  			<el-table-column prop="phone" :label="this.$t('users.fields.phone')" align="center" width="150"/>
		  		    <el-table-column prop="workNo" :label="'SAP'" align="center" width="150"/>
		  			
		  		    
		  		  </dsn-table>
		  		  <!-- <DsnFooter> -->
		  		    <dsn-pagination
		  		        v-if="showPage"
						style="float: right;"
		  		        background
		  		        layout="->,total,prev,pager,next,sizes"
		  		        :total="pagingConfig2.total"
		  		        :page-size="pagingConfig2.pageSize"
		  		        :page-sizes="[5, 10, 15, 20]"
		  		        :current-page="pagingConfig2.current"
		  		        @size-change="handlePageSizeChange2"
		  		        @current-change="handleCurrentChange2"
		  		    ></dsn-pagination>
		  		  <!-- </DsnFooter> -->
		  </div>
		  
	  </div>
	  
	  
	  
	  
	  
	  
      

      
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
      
    </DsnPanel>
    <el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
      <span>是否确认删除{{ selectionList.length }}条数据？</span>
      <span slot="footer" class="dialog-footer">
        <dsn-button @click="deleteDialog = false">取 消</dsn-button>
        <dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
      </span>
    </el-dialog>

    <role-add ref="roleAddRef" v-on:add-role-success="getRoleList"/>
    <role-edit ref="roleEditRef" v-on:update-role-success="getRoleList"/>
  </div>
</template>
<script>
import {mapMutations,mapGetters} from "vuex";
import DsnFooter from "@/views/layout/dsn-footer";
import RoleAdd from "@/views/mes/user/role/components/roleAdd";
import RoleEdit from "@/views/mes/user/role/components/roleEdit";
import {deleteRoleById, getRoleList} from "@/api/efms/roles/role.api";
import _ from "lodash";
import {getAllPermissionList} from "@/api/efms/permission/permission.api";
import XEUtils from "xe-utils";
import {assignUserRole, deleteUserById, getUserList, revokeUserRole} from "@/api/efms/users/users.api";

export default {
  name: 'equipmentPositionRefSettings',
  components: {RoleEdit, RoleAdd, DsnFooter},
  inject: ["defaltDialogWidth"],
  data() {
    return {
      listData: [],
      queryParam: {
        username: null,
        realname: null
      },
      showPage: true,
      pagingConfig: {
        current: 1,
        pageSize: 10,
        count: 0,
        tableLoading: false,
        total: 0,
        tableData: [],
      },
      deleteDialog: false,
      selectionList: [],
      showAddRoleDialog: false,
	  
	  isAddBtn:false,
	  isEditBtn:false,
	  isDelBtn:false,
	  
	  tableLoading2: false,
	  flatList: [],
	  allPermissionTreeList: [],
	  currentRole: null,
	  
	  pagingConfig2: {
	    current: 1,
	    pageSize: 10,
	    count: 0,
	    tableLoading: false,
	    total: 0,
	    tableData: [],
	  },
	  tableRowIndex:null,
    }
  },
  computed: {
    // ...mapGetters(["roleEditList"]),
  },
  // watch: {
  //     selectionList(data) {
  //       this.selectRow = [];
  //       if (data.length > 0) {  
  //         data.forEach((item, index) => {
  //           this.selectRow.push(this.pagingConfig.tableData.indexOf(item));
  //         });
  //       }
  //     }
  // },
  created() {
    this.getRoleList();
	
	var permissionsList = JSON.parse(localStorage.getItem("USER_PERMISSIONS"));
	
	if(permissionsList.indexOf("api:roles:add") != -1){
		this.isAddBtn = true;
	}
	if(permissionsList.indexOf("api:roles:edit") != -1){
		this.isEditBtn = true;
	}
	if(permissionsList.indexOf("api:roles:del") != -1){
		this.isDelBtn = true;
	}

  },
  activated() {
    this.getRoleList();
  },
  methods: {
	   handleRowClick(row) {
	        this.tableRowIndex = this.getArrayIndex(this.tableData, row); // 获取当前点击行下标
	  　　　 // ... 点击当前行，进行的操作
	      },
	      tablerowStyle({ row, rowIndex }) {
			  console.log(this.tableRowIndex);
			  console.log({ row, rowIndex });
	        if ((rowIndex == this.tableRowIndex) && this.tableRowIndex!=null) {
				// this.$refs.multipleTable.toggleRowSelection(row);
	          return 'highlight-row'
	        }
			
	      },
	      // 获取一个元素在数组中的下标
	      getArrayIndex(arr, obj) {
			  // console.log(arr, obj);
			for(var i=0;i<arr.length;i++){
				if(arr[i].id == obj.id){
					return i;
				}
			}
	        // while (i--) {
	        //   if (arr[i].id === obj.id) {
	        //     return i;
	        //   }
	        // }
	        // return -1;
	      },
	  
	  
	  toColTreeData(treeData) {
	    const options = {children: 'children'}
	    const list = []
	    const keyMap = {}
	    XEUtils.eachTree(treeData, (item, index, result, paths, parent) => {
	      keyMap[item.id] = item
	      item.keys = parent ? parent.keys.concat([item.id]) : [item.id]
	      if (!item.children || !item.children.length) {
	        const row = {}
	        item.keys.forEach((key, index) => {
	          const level = index + 1
	          const obj = keyMap[key]
	          row[`check${level}`] = false
	          row[`id${level}`] = obj.id
	          row[`permissionName${level}`] = obj.permissionName
	          row[`permissionCode${level}`] = obj.permissionCode
	        })
	        list.push(row)
	      }
	    }, options)
	    this.keyMap = keyMap
	    this.flatList = [...list]
	  },
	  initPermissionList() {
	    this.tableLoading = true
	    getAllPermissionList().then(res => {
	      const {status, data} = res;
	      if (status === 200 && data.resultCode === 0) {
	        let tempList = data.data;
	        this.toColTreeData(tempList);
	        console.info(this.flatList)
	        if (this.flatList != null && this.flatList.length > 0) {
	          if (this.currentRole != null) {
	            let pids = this.currentRole.permissionList;
	            console.info(this.flatList)
	            this.flatList.forEach(flatItem => {
	              if (pids != null && pids.indexOf(flatItem.id1) >= 0) {
	                flatItem.check1 = true
	              }
	              if (pids != null && pids.indexOf(flatItem.id2) >= 0) {
	                flatItem.check2 = true
	              }
	              if (pids != null && pids.indexOf(flatItem.id3) >= 0) {
	                flatItem.check3 = true
	              }
	  			
	  			if(flatItem.permissionName2 == 'SOP' || flatItem.permissionName2 == 'SOP查看'){
	  				flatItem.isshow = false;
	  			}
	  			else{
	  				flatItem.isshow = true;
	  			}
	            })
	          }
	          this.allPermissionTreeList = [...tempList]
	        }
	        this.$forceUpdate();
	      } else {
	        this.allPermissionTreeList = []
	        this.notification().error({
	          body: data.errMsg,
	        });
	      }
	      this.tableLoading = false
	    })
	  },
	  rowspanMethod({row, _rowIndex, column, visibleData}) {
	    const fields = ['permissionName1', 'permissionName2', 'permissionName3']
	    const cellValue = row[column.property]
	    if (cellValue && fields.includes(column.property)) {
	      const prevRow = visibleData[_rowIndex - 1]
	      let nextRow = visibleData[_rowIndex + 1]
	      if (prevRow && prevRow[column.property] === cellValue) {
	        return {rowspan: 0, colspan: 0}
	      } else {
	        let countRowspan = 1
	        while (nextRow && nextRow[column.property] === cellValue) {
	          nextRow = visibleData[++countRowspan + _rowIndex]
	        }
	        if (countRowspan > 1) {
	          return {rowspan: countRowspan, colspan: 1}
	        }
	      }
	    }
	  },
	  check1ChangeEvent(row, checked) {
	    const {flatList} = this
	    let childList = flatList.filter(item => item.permissionName1 === row.permissionName1)
	    childList.forEach(item => {
	      item.check1 = checked
	    })
	    childList = this.flatList.filter(item => item.id1 === row.id1)
	    childList.forEach(item => {
	      this.check2ChangeEvent(item, checked)
	    })
	  },
	  check2ChangeEvent(row, checked) {
	    const {flatList} = this
	    let childList = flatList.filter(item => item.id1 === row.id1 && item.permissionName2 === row.permissionName2)
	    childList.forEach(item => {
	      item.check2 = checked
	    })
	    childList = this.flatList.filter(item => item.id2 === row.id2)
	    childList.forEach(item => {
	      this.check3ChangeEvent(item, checked)
	    })
	    
	    if (!checked) {//判断同级别有多少个false
	      let pid1 = row["id1"]
	      let pid2 = row["id2"]
	      let groupList = flatList.filter(s => s["id1"] === pid1 && s["id2"] === pid2  && s["id2"] !== row["id2"] && s["check2"])
	      if (groupList == null || groupList.length === 0) {
	        let parentList = flatList.filter(s => s["id1"] === pid1 && s["id2"] === pid2)
	        console.info(parentList);
	        parentList.forEach(v => {
	          v.check2 = false
	        })
	    
	        let parentSameLevelList = flatList.filter(s => s["id1"] === pid1 && s["check1"])
	        if (parentSameLevelList == null || parentSameLevelList.length === 0) {
	          let rootList = flatList.filter(s => s["id1"] === pid1)
	          if (rootList != null && rootList.length > 0) {
	            rootList.forEach(item => {
	              item.check1 = false
	            })
	    
	          }
	    
	        }
	      }
	    }
	    
	    
	  },
	  check3ChangeEvent(row, checked) {//取消所有的则清空上级
	    console.info(row)
	    console.info(checked)
	    const {flatList} = this
	    let childList = flatList.filter(item => item.id2 === row.id2 && item.permissionName3 === row.permissionName3)
	    childList.forEach(item => {
	      item.check3 = checked
	    })
	    
	    childList = flatList.filter(item => item.id1 === row.id1 && item.permissionName2 === row.permissionName2)
	    childList.forEach(item => {
	      item.check2 = true
	    })
	    
	    childList = flatList.filter(item => item.permissionName1 === row.permissionName1)
	    childList.forEach(item => {
	      item.check1 = true
	    })
	  
	  
	    if (!checked) {//判断同级别有多少个false
	      let pid1 = row["id1"]
	      let pid2 = row["id2"]
	      let groupList = flatList.filter(s => s["id1"] === pid1 && s["id2"] === pid2 && s["id3"] !== row["id3"] && s["check3"])
	      if (groupList == null || groupList.length === 0) {
	        let parentList = flatList.filter(s => s["id1"] === pid1 && s["id2"] === pid2)
	        console.info(parentList);
	        parentList.forEach(v => {
	          v.check2 = false
	        })
	  
	        let parentSameLevelList = flatList.filter(s => s["id1"] === pid1 && s["id2"] !== pid2 && s["check2"])
	        if (parentSameLevelList == null || parentSameLevelList.length === 0) {
	          let rootList = flatList.filter(s => s["id1"] === pid1)
	          if (rootList != null && rootList.length > 0) {
	            rootList.forEach(item => {
	              item.check1 = false
	            })
	  
	          }
	  
	        }
	      }
	    }
	  },
	  
	  // getSysUserList() {
	  //   this.initMainList2();
	  // },
	  initMainList2(showPage) {
		  console.log('selectionList ---------------------',this.selectionList);
		  // console.log('showPage ---------------------',showPage);
	    if (showPage == true) {
	      this.pagingConfig2.current = 1;
	    }
	    const data = {
	      ...this.queryParam,
		  roleId:this.selectionList[0].id,
	      page: this.pagingConfig2.current,
	      pageSize: this.pagingConfig2.pageSize
	    };
		// console.log('data ---------------------',data);
	    this.showPage = showPage;
	    this.pagingConfig2.tableLoading = true
	    getUserList(data).then((response) => {
	      const {data, status} = response;
	      this.pagingConfig2.tableLoading = false
	      if (status === 200) {
	        this.showPage = true;
	        this.pagingConfig2.total = data.totalCount;
	  	  
	        this.pagingConfig2.tableData = [...data.data]
	  
	      } else {
	        this.notification().warn({
	          body: data.errMsg
	        });
	      }
	    })
	  },
	  
    ...mapMutations(["ROLEEDITLIST"]),
	// ...mapGetters(["roleEditList"]),
    sexFormatter(data) {
      return data === 1 ? this.$t('users.fields.male') : this.$t('users.fields.female')
    },
    handleQuery(formNames) {
      this.$refs[formNames].validate(valid => {
        if (valid) {
          this.getRoleList();
        } else {
          return false;
        }
      });
    },
    handleReset(formName) {
      console.info(this.$refs[formName])
      this.$refs[formName].resetFields();
      this.handleQuery(formName);
    },
    handlePageSizeChange(pageSize) {
      this.pagingConfig.pageSize = pageSize;
      this.pagingConfig.current = 1;
      this.getRoleList();
    },
    handleCurrentChange(currentChange) {
      this.pagingConfig.current = currentChange;
      this.getRoleList();
    },
	handlePageSizeChange2(pageSize) {
	  this.pagingConfig2.pageSize = pageSize;
	  this.pagingConfig2.current = 1;
	  this.initMainList2(false);
	},
	handleCurrentChange2(currentChange) {
	  this.pagingConfig2.current = currentChange;
	  this.initMainList2(false);
	},

    handleSelectionChange(val) {
		if (val.length > 1) {
			this.$refs.multipleTable.clearSelection();
			this.$refs.multipleTable.toggleRowSelection(val.pop());
		} else {
			this.selectionList = val;
			this.tableRowIndex = this.getArrayIndex(this.pagingConfig.tableData, this.selectionList[0]); // 获取当前点击行下标
		}
		
		
		this.initMainList2(true);
		this.initPermissionList();
		// this.cloneList = _.cloneDeep(JSON.parse(JSON.stringify(this.roleEditList)));
		// this.currentRole =this.cloneList[0] ||JSON.parse(localStorage.getItem("roleEditList"))[0];
			
		this.cloneList = _.cloneDeep(this.selectionList);
		this.currentRole = this.cloneList[0] || this.selectionList;
		
    },
    selectCheckBox(row) {
		
      this.$refs.multipleTable.toggleRowSelection(row);
	  // this.$refs.multipleTable.toggleRowSelection(val.pop());
    },
    handleEdit() {
      const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      // this.ROLELIST(tempArr);
      // localStorage.setItem("roleList", JSON.stringify(this.selectionList));
      // this.$router.push({
      //   name: "userEdit",
      //   query: {operateType: "edit"}
      // });
      this.handleRoleEdit(true, tempArr[0]);
    },
    handleAdd() {
      // const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.USERSLIST([]);
      // localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "userAdd",
        query: {operateType: "add"}
      });
    },
    handleDelete() {
      const {selectionList} = this;
      const params = selectionList.map(item => ({
        id: item.id,
      }));
      console.info(params)
      deleteRoleById(params[0].id).then(result => {
        const {status, data} = result;
        if (status === 200 && data.resultCode === 0) {
          this.notification().success({
            body: '操作成功'
          });
          this.getRoleList();
        } else {
          this.notification().error({
            body: data.errMsg
          });
        }
      })
    },
    handleDeleteCheck() {
      this.messageBox()
          .then(() => {
            this.handleDelete();
          })
          .catch(() => {
            this.notification().warn({
              body: "已经取消删除"
            });
          });
    },
    getRoleList() {
      this.initMainList();
    },
    initMainList(showPage) {
		
      if (!showPage) {
        this.pagingConfig.current = 1;
      }
      const data = {
        ...this.queryParam,
        page: this.pagingConfig.current,
        pageSize: this.pagingConfig.pageSize
      };
      this.showPage = showPage;
      this.pagingConfig.tableLoading = true
      getRoleList(data).then((response) => {
        const {data, status} = response;
        this.pagingConfig.tableLoading = false
        if (status === 200) {
          this.showPage = true;
          this.pagingConfig.total = data.totalCount;
          this.pagingConfig.tableData = [...data.data]
          // this.notification().success({
          //   body: '请求成功',
          //   duration: 1
          // });
        } else {
          this.notification().warn({
            body: data.errMsg
          });
        }
      })
    },
    handleRoleAdd() {
      this.$refs.roleAddRef.handleDialogShow(true, {});
    },
    handleRoleEdit(statusFlag, scope) {
      console.info(scope)
      // const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      // this.ROLELIST (tempArr);
      // localStorage.setItem("roleList", JSON.stringify(this.selectionList));
      this.$refs.roleEditRef.handleDialogShow(true, scope);
    },
    handleRowRoleEdit(statusFlag, scope) {
      console.info(scope)
      // const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      // this.ROLELIST (tempArr);
      // localStorage.setItem("roleList", JSON.stringify(this.selectionList));
      this.$refs.roleEditRef.handleDialogShow(true, scope.row);
    },
    assignRolePermission(scope) {
      let tempList = []
      tempList.push(scope.row)
      const tempArr = JSON.parse(JSON.stringify(tempList));
      this.ROLEEDITLIST(tempArr);
      localStorage.setItem("roleEditList", JSON.stringify(tempList));
      this.$router.push({
        name: "rolePermissionAssign",
        query: {operateType: "assign",role:scope.row.roleName}
      });
    },
    assignRoleToUsers(scope){
      this.$router.push({
        name: "roleUserAssign",
        query: {operateType: "roleUserAssign",roleId:scope.row.id}
      });
    },
	
	addrole() {
	  let tempList = []
	  // tempList.push(scope.row)
	  const tempArr = JSON.parse(JSON.stringify(tempList));
	  this.ROLEEDITLIST(tempArr);
	  localStorage.setItem("roleEditList", JSON.stringify(tempList));
	  this.$router.push({
	    name: "rolePermissionAssign",
	    query: {operateType: "assign",role:''}
	  });
	},
	
	editrole() {
	  let tempList = []
	  tempList = this.selectionList
	  const tempArr = JSON.parse(JSON.stringify(tempList));
	  var tempListStr= JSON.stringify(tempList);
	  this.ROLEEDITLIST(tempArr);
	  localStorage.setItem("roleEditList", JSON.stringify(tempList));
	  this.$router.push({
	    name: "roleUserAssign",
	    query: {operateType: "assign",roleEditList:tempListStr}
	  });
	},
	
  }
}
</script>
<style lang="scss" scoped>
#page{
	// width: 100vw;
	// height: 100vh;
	  
	  
	  // background: rgb(24, 25, 35);
	  // display: flex;
	  // align-items: center;
	  // justify-content: center;
}
::v-deep .vxe-checkbox--icon::before{
	border-color: #409eff !important;
}
::v-deep .vxe-checkbox--label{
	color:#000000 !important;
}

::v-deep .el-table__body tr.current-row>td {
		background: #a0cfff !important;
	}
	::v-deep .highlight-row{
		background: #a0cfff !important;
	}

.role-list-container {
  .operation {
    width: 100%;
    // height: 48px;
	margin:10px 0;

    .dsn-button {
      margin: 0 8px 0 0;
    }
  }
  
  .info {
    background-color: #ffffff;
    padding: 16px;
  
    .userFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }
  
    .userForm {
      .el-form-item {
        margin: 0 24px 16px 0;
  
        .el-select {
          width: 320px;
        }
  
        .el-input {
          width: 320px;
        }
      }
    }
  }
  
}

//::v-deep.el-table .cell {
//  font-size: 18px;
//}

//::v-deep .el-form-item__label {
//  font-size: 25px;
//}
//
//::v-deep .el-form-item__content {
//  font-size: 25px;
//}
</style>
