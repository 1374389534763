<template>
  <el-dialog title="新建角色" :visible.sync="showAddRoleDialog" :width="'35%'" left>
    <el-form :model="roleForm" :inline-message="true" ref="roleAddForm" :rules="roleFormRules" label-position="left">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item :label="this.$t('roles.fields.roleName')" prop="roleName" :label-width="'150px'">
            <dsn-input size="medium"
                       v-model.trim="roleForm.roleName"
                       placeholder="请输入角色名称"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item :label="this.$t('roles.fields.defaultRole')" prop="remark" :label-width="'150px'">
            <!--            <dsn-input size="medium" type="textarea"-->
            <!--                       v-model.trim="roleForm.remark"-->
            <!--                       placeholder="请输入备注"-->
            <!--            />-->
            <el-checkbox v-model="roleForm.defaultRoleFlag" :true-label="1" :false-label="0">默认角色</el-checkbox>
<!--            <el-radio v-model="roleForm.defaultRoleFlag" :label="0">默认角色</el-radio>-->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item :label="this.$t('roles.fields.remark')" prop="remark" :label-width="'150px'">
            <dsn-input size="medium" type="textarea"
                       v-model.trim="roleForm.remark"
                       placeholder="请输入备注"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showAddRoleDialog = false">取 消</el-button>
    <el-button type="primary" @click="checkAdd(['roleAddForm'])">保 存</el-button>
  </span>
  </el-dialog>
</template>
<script>
import {addRole} from "@/api/efms/roles/role.api";

export default {
  name: 'roleAdd',
  data() {
    return {
      showAddRoleDialog: false,
      roleForm: {
        roleName: null,
        roleCode: null,
        remark: null,
        defaultRoleFlag: 0
      }
    }
  },
  computed: {
    roleFormRules: function () {
      let that = this
      return {
        roleName: [{required: true, message: "请输入角色名称", trigger: "change"}],
      }
    }
  },
  methods: {
    handleDialogShow(showStatus, data) {
      console.info(showStatus);
      console.info(data);
      this.showAddRoleDialog = showStatus;
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      let postData = JSON.parse(JSON.stringify(this.roleForm));
      console.info(postData);
      addRole(postData).then(res => {
        const {data, status} = res;
        console.info(status)
        if (status === 200 && data.resultCode === 0) {
          this.showAddRoleDialog = false;
          this.$refs['roleAddForm'].resetFields();
          this.notification().success({
            body: "添加成功",
          });
          this.$emit('add-role-success')
        } else {
          this.notification().error({
            body: data.errMsg
          });
        }
      });
    }
  }
}

</script>
<style lang="scss" scoped>
</style>
