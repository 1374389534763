<template>
  <div class="role-list-container" id="page">
    <DsnPanel>
      <div class="operate">
        <dsn-button
            size="medium"
            icon="el-icon-back"
            type="primary"
			style="margin: 0 !important;"
            @click.native="handleBack"
        >返回
        </dsn-button>
       <dsn-button-save
            @click.native="checkSave(['roleAddForm'])" size="medium">保存
        </dsn-button-save>
        
      </div>
	  <div style="display: flex;justify-content: flex-start;">
		  
		  <div style="margin: 0 10px 0 0;width:25vw;">
			  <div class="operation">
			    <span style="color: #4E81BD;font-size: 25px;">基础信息</span>
			  </div>
			  
		  	<el-form :model="roleForm" :inline-message="true" ref="roleAddForm" :rules="roleFormRules" label-position="left">
		  	  <el-row>
		  	    <el-col :span="24">
		  	      <el-form-item :label="this.$t('roles.fields.roleName')" prop="roleName" :label-width="'150px'">
		  	        <dsn-input size="medium"
		  	                   v-model.trim="roleForm.roleName"
		  	                   placeholder="请输入角色名称"
		  	        />
		  	      </el-form-item>
		  	    </el-col>
		  	  </el-row>
		  	  
		  	  <el-row>
		  	    <el-col :span="24">
		  	      <el-form-item :label="this.$t('roles.fields.remark')" prop="remark" :label-width="'150px'">
		  	        <dsn-input size="medium" type="textarea"
		  	                   v-model.trim="roleForm.remark"
		  	                   placeholder="请输入备注"
		  	        />
		  	      </el-form-item>
		  	    </el-col>
		  	  </el-row>
			  
			  <el-row :gutter="20">
			    <el-col :span="24">
			      <el-form-item :label="this.$t('roles.fields.defaultRole')" prop="defaultRoleFlag" :label-width="'150px'">
			  
			        <el-checkbox v-model="roleForm.defaultRoleFlag" :true-label="1" :false-label="0">默认角色</el-checkbox>
			      </el-form-item>
			    </el-col>
			  </el-row>
			  
			  
		  	</el-form>
		  		  
		  		  
		  </div>
		  
		  <div style="margin: 0 10px 0 0;width:25vw;">
			  <div class="operation">
			    <span style="color: #4E81BD;font-size: 25px;">权限分配</span>
			  </div>
		  	<vxe-table
		  	    border
		  	    :scroll-y="{enabled: false}"
		  	    :span-method="rowspanMethod"
		  	    :loading="tableLoading2"
				resizable
		  		:header-cell-style="{'font-size':'17px','background':'#4e81bd','font-family':'微软雅黑','font-weight':'normal','color':'#ffffff','border':'1px #e8eaec solid','padding':'7px !important'}"
		  		max-height="680"
		  	    :data="flatList.filter(v => v.isshow == true)">
		  	  <vxe-table-column field="permissionName1" title="模块" width="130">
		  	    <template v-slot="{ row }">
		  	      <vxe-checkbox v-model="row.check1" @change="check1ChangeEvent(row, row.check1)">{{
		  	          row.permissionName1
		  	        }}
		  	      </vxe-checkbox>
		  	    </template>
		  	  </vxe-table-column>
		  	  <vxe-table-column field="permissionName2" title="功能" width="180">
		  	    <template v-slot="{ row }">
		  	      <vxe-checkbox v-model="row.check2" @change="check2ChangeEvent(row, row.check2)" v-if="row.isshow == true">{{
		  	          row.permissionName2
		  	        }}
		  	      </vxe-checkbox>
		  	    </template>
		  	  </vxe-table-column>
		  	  <vxe-table-column field="permissionName3" title="权限" width="140">
		  	    <template v-slot="{ row }">
		  	      <vxe-checkbox v-model="row.check3" v-if="row.permissionName3!=null && row.isshow == true" @change="check3ChangeEvent(row, row.check3)">{{ row.permissionName3 }}
		  	      </vxe-checkbox>
		  	    </template>
		  	  </vxe-table-column>
		  	</vxe-table>
		  </div>
		  
		  <div style="width: 33vw;">
			  <div class="operation">
			    <span style="color: #4E81BD;font-size: 25px;">用户分配</span>
			  </div>
			  
			  <div class="operation">
			    <el-form
			        :inline="true"
			        :model="queryParam5"
			        ref="userQueryForm"
			        :rules="{}"
			        @submit.native.prevent
			    >
			      <!-- <el-form-item :label="`${this.$t('users.fields.workNo')}:`" prop="workNo" size="medium">
			        <dsn-input v-model="queryParam5.workNo" size="medium" style="width: 5vw;"
			                   :placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('users.fields.workNo')}`"></dsn-input>
			      </el-form-item> -->
				  <el-form-item :label="`${this.$t('users.fields.workNo')}/`+`${this.$t('users.fields.realname')}/`+`${this.$t('users.fields.phone')}:`" prop="realname" size="medium">
				    <dsn-input v-model="queryParam5.realname" size="medium" style="width:11vw;"
				               :placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('users.fields.workNo')+'/'+this.$t('users.fields.realname')+this.$t('users.fields.phone')}`"></dsn-input>
				  </el-form-item>
			      <!-- <el-form-item :label="`${this.$t('users.fields.phone')}:`" prop="phone" size="medium">
			        <dsn-input v-model="queryParam5.phone" size="medium" style="width: 5vw;"
			                   :placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('users.fields.phone')}`"></dsn-input>
			      </el-form-item> -->
			      
			      <el-form-item>
			    
			        <dsn-button
			            size="medium"
			            type="primary"
			            icon="el-icon-search"
			            @click="handleQuery2(['userQueryForm'])"
			        >{{ $t('commbtn.query') }}
			        </dsn-button>
			        
			      </el-form-item>
			    </el-form>
			  </div>
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
		  		  <dsn-table
		  		      ref="multipleTable2"
		  		      :data="pagingConfig2.tableData"
		  		      v-loading="pagingConfig2.tableLoading"
		  		      tooltip-effect="dark"
		  		      style="width: 100%;height:63vh;"
					  @selection-change="handleSelectionChange2"
		  		  >
		  		    <el-table-column type="selection" width="55" align="center"/>
					
		  		    
		  		    <el-table-column prop="realname" :label="this.$t('users.fields.realname')" align="center" width="150"/>
		  			<el-table-column prop="phone" :label="this.$t('users.fields.phone')" align="center" width="150"/>
		  		    <el-table-column prop="workNo" :label="'SAP'" align="center" width="150"/>
		  			
		  		    
		  		  </dsn-table>
				  
				  
				  
				  <dsn-pagination
				      v-if="showPage"
				  	style="float: right;"
				      background
				      layout="->,total,prev,pager,next,sizes"
				      :total="pagingConfig2.total"
				      :page-size="pagingConfig2.pageSize"
				      :page-sizes="[5, 10, 15, 20]"
				      :current-page="pagingConfig2.current"
				      @size-change="handlePageSizeChange2"
				      @current-change="handleCurrentChange2"
				  ></dsn-pagination>
				  
				  
				  
		  </div>
		  
	  </div>
	  
	  
	  
	  
	  
	  
      

      
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
      
    </DsnPanel>
	
    <el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
      <span>是否确认删除{{ selectionList.length }}条数据？</span>
      <span slot="footer" class="dialog-footer">
        <dsn-button @click="deleteDialog = false">取 消</dsn-button>
        <dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
      </span>
    </el-dialog>

    <role-add ref="roleAddRef" v-on:add-role-success="getRoleList"/>
    <role-edit ref="roleEditRef" v-on:update-role-success="getRoleList"/>
  </div>
</template>
<script>
import {mapMutations,mapGetters} from "vuex";
import DsnFooter from "@/views/layout/dsn-footer";
import RoleAdd from "@/views/mes/user/role/components/roleAdd";
import RoleEdit from "@/views/mes/user/role/components/roleEdit";
import {deleteRoleById, getRoleList,addRole,saveRolePermissions} from "@/api/efms/roles/role.api";
import _ from "lodash";
import {getAllPermissionList} from "@/api/efms/permission/permission.api";
import XEUtils from "xe-utils";
import {assignUserRole, deleteUserById, getUserList, revokeUserRole} from "@/api/efms/users/users.api";

export default {
  name: 'rolePermissionAssign',
  components: {RoleEdit, RoleAdd, DsnFooter},
  inject: ["defaltDialogWidth"],
  data() {
    return {
      listData: [],
	  roleForm: {
	    id: null,
	    roleName: null,
	    roleCode: null,
	    remark: null,
	  		defaultRoleFlag:0
	  },
	  queryParam5: {
        workNo: null,
        realname: null,
		phone:null,
      },
      queryParam: {
        username: null,
        realname: null
      },
      showPage: true,
      pagingConfig: {
        current: 1,
        pageSize: 10,
        count: 0,
        tableLoading: false,
        total: 0,
        tableData: [],
      },
      deleteDialog: false,
      selectionList: [],
	  selectionList2: [],
      showAddRoleDialog: false,
	  
	  isAddBtn:false,
	  isEditBtn:false,
	  isDelBtn:false,
	  
	  tableLoading2: false,
	  flatList: [],
	  allPermissionTreeList: [],
	  currentRole: null,
	  
	  pagingConfig2: {
	    current: 1,
	    pageSize: 10,
	    count: 0,
	    tableLoading: false,
	    total: 0,
	    tableData: [],
	  },
    }
  },
  computed: {
	  roleFormRules: function () {
	    let that = this
	    return {
	      roleName: [{required: true, message: "请输入角色名称", trigger: "change"}],
	    }
	  }
    // ...mapGetters(["roleEditList"]),
  },
  created() {
    this.getRoleList();
	this.initMainList2(false);
	this.initPermissionList();

  },
  activated() {
    this.getRoleList();
	this.initMainList2(false);
	this.initPermissionList();
  },
  methods: {
	handleQuery2(formNames) {
	  this.$refs[formNames].validate(valid => {
	    if (valid) {
	      this.initMainList2(false)
	    } else {
	      return false;
	    }
	  });
	},
	  
	  
	  
	checkSave(formName) {
	  let count = 0;
	  formName.forEach((element) => {
	    this.$refs[element].validate((valid) => {
	      if (valid) {
	        count++;
	      } else {
	        return false;
	      }
	    });
	  });
	  // console.info(count)
	  if (count >= 1) {
	    this.handleDataSave();
	  }
	}, 

	  
	handleDataSave() {
	  let postData = JSON.parse(JSON.stringify(this.roleForm));
	  console.info('postData ------------',postData);
	  addRole(postData).then(res => {
	    const {data, status} = res;
	    console.info(res)
	    if (status === 200 && data.resultCode === 0) {
			this.SaveRoleList(data.data.id);
			this.SaveUserList(data.data.id);
		  // this.$refs['roleAddForm'].resetFields();
	      this.notification().success({
	        body: "保存成功",
	      });
	    } else {
	      this.notification().error({
	        body: data.errMsg
	      });
	    }
	  });
	},  
	  
	SaveRoleList(roleid) {
	  let pToSaveList = []
	  // console.info(this.flatList)
	  let levelOne = Array.from(new Set(this.flatList.filter(s => s.check1).map(z => z.id1)));
	  let levelTwo = Array.from(new Set(this.flatList.filter(s => s.check2).map(z => z.id2)));
	  let levelThree = Array.from(new Set(this.flatList.filter(s => s.check3).map(z => z.id3)));
	  // console.info(levelOne)
	  // console.info(levelTwo)
	  // console.info(levelThree)
	  let allPIds = []
	  if (levelOne.length > 0) {
	    levelOne.forEach(v => {
	      pToSaveList.push(v)
	    })
	
	  }
	  if (levelTwo.length > 0) {
	    levelTwo.forEach(v => {
	      pToSaveList.push(v)
	    })
	
	  }
	  if (levelThree.length > 0) {
	    levelThree.forEach(v => {
	      pToSaveList.push(v)
	    })
	  }
	  let postData = {
	    roleId: roleid,
	    permissionIdList: pToSaveList
	  }
	  console.info('postData2 ------------',postData);
	  saveRolePermissions(postData).then(res => {
	    const {status, data} = res
	    if (status === 200 && data.resultCode === 0) {
	      this.notification().success({
	        body: "保存成功!",
	      });
	    } else {
	      this.notification().success({
	        body: data.errMsg,
	      });
	    }
	  })
	},  
	  
	SaveUserList(roleid) {
	  if (this.selectionList2.length > 0) {
	    let dataList = JSON.parse(JSON.stringify(this.selectionList2));
	    let userIds = dataList == null || dataList.length <= 0 ? [] : dataList.map(s => s.id);
	    // console.info(userIds)
	    let postData = {
	      roleId: roleid,
	      userIdList: userIds
	    }
		console.info('postData3 ------------',postData);
	    assignUserRole(postData).then(res => {
	      const {status, data} = res
	      if (status === 200 && data.resultCode === 0) {
			  this.handleBack();
	        this.notification().success({
	          body: '保存成功'
	        });
	      } else {
	        this.notification().error({
	          body: '保存失败'
	        });
	      }
	    })
	  }
	},  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  handleBack() {
	    this.$router.pushAndClear(
	        {name: "roleList"},
	        {
	          deleteItem: this.$route,
	          current: this.$route,
	        }
	    );
	  },
	  toColTreeData(treeData) {
	    const options = {children: 'children'}
	    const list = []
	    const keyMap = {}
	    XEUtils.eachTree(treeData, (item, index, result, paths, parent) => {
	      keyMap[item.id] = item
	      item.keys = parent ? parent.keys.concat([item.id]) : [item.id]
	      if (!item.children || !item.children.length) {
	        const row = {}
	        item.keys.forEach((key, index) => {
	          const level = index + 1
	          const obj = keyMap[key]
	          row[`check${level}`] = false
	          row[`id${level}`] = obj.id
	          row[`permissionName${level}`] = obj.permissionName
	          row[`permissionCode${level}`] = obj.permissionCode
	        })
	        list.push(row)
	      }
	    }, options)
	    this.keyMap = keyMap
	    this.flatList = [...list]
	  },
	  initPermissionList() {
	    this.tableLoading = true
	    getAllPermissionList().then(res => {
	      const {status, data} = res;
	      if (status === 200 && data.resultCode === 0) {
	        let tempList = data.data;
	        this.toColTreeData(tempList);
	        console.info(this.flatList)
	        if (this.flatList != null && this.flatList.length > 0) {
	     //      if (this.currentRole != null) {
	     //        let pids = this.currentRole.permissionList;
	     //        console.info(this.flatList)
	            this.flatList.forEach(flatItem => {
	  			
	  			if(flatItem.permissionName2 == 'SOP' || flatItem.permissionName2 == 'SOP查看'){
	  				flatItem.isshow = false;
	  			}
	  			else{
	  				flatItem.isshow = true;
	  			}
	            })
	     //      }
	          this.allPermissionTreeList = [...tempList]
	        }
	        this.$forceUpdate();
	      } else {
	        this.allPermissionTreeList = []
	        this.notification().error({
	          body: data.errMsg,
	        });
	      }
	      this.tableLoading = false
	    })
	  },
	  rowspanMethod({row, _rowIndex, column, visibleData}) {
	    const fields = ['permissionName1', 'permissionName2', 'permissionName3']
	    const cellValue = row[column.property]
	    if (cellValue && fields.includes(column.property)) {
	      const prevRow = visibleData[_rowIndex - 1]
	      let nextRow = visibleData[_rowIndex + 1]
	      if (prevRow && prevRow[column.property] === cellValue) {
	        return {rowspan: 0, colspan: 0}
	      } else {
	        let countRowspan = 1
	        while (nextRow && nextRow[column.property] === cellValue) {
	          nextRow = visibleData[++countRowspan + _rowIndex]
	        }
	        if (countRowspan > 1) {
	          return {rowspan: countRowspan, colspan: 1}
	        }
	      }
	    }
	  },
	  check1ChangeEvent(row, checked) {
	    const {flatList} = this
	    let childList = flatList.filter(item => item.permissionName1 === row.permissionName1)
	    childList.forEach(item => {
	      item.check1 = checked
	    })
	    childList = this.flatList.filter(item => item.id1 === row.id1)
	    childList.forEach(item => {
	      this.check2ChangeEvent(item, checked)
	    })
	  },
	  check2ChangeEvent(row, checked) {
	    const {flatList} = this
	    let childList = flatList.filter(item => item.id1 === row.id1 && item.permissionName2 === row.permissionName2)
	    childList.forEach(item => {
	      item.check2 = checked
	    })
	    childList = this.flatList.filter(item => item.id2 === row.id2)
	    childList.forEach(item => {
	      this.check3ChangeEvent(item, checked)
	    })
	    
	    if (!checked) {//判断同级别有多少个false
	      let pid1 = row["id1"]
	      let pid2 = row["id2"]
	      let groupList = flatList.filter(s => s["id1"] === pid1 && s["id2"] === pid2  && s["id2"] !== row["id2"] && s["check2"])
	      if (groupList == null || groupList.length === 0) {
	        let parentList = flatList.filter(s => s["id1"] === pid1 && s["id2"] === pid2)
	        console.info(parentList);
	        parentList.forEach(v => {
	          v.check2 = false
	        })
	    
	        let parentSameLevelList = flatList.filter(s => s["id1"] === pid1 && s["check1"])
	        if (parentSameLevelList == null || parentSameLevelList.length === 0) {
	          let rootList = flatList.filter(s => s["id1"] === pid1)
	          if (rootList != null && rootList.length > 0) {
	            rootList.forEach(item => {
	              item.check1 = false
	            })
	    
	          }
	    
	        }
	      }
	    }
	    
	    
	  },
	  check3ChangeEvent(row, checked) {//取消所有的则清空上级
	    console.info(row)
	    console.info(checked)
	    const {flatList} = this
	    let childList = flatList.filter(item => item.id2 === row.id2 && item.permissionName3 === row.permissionName3)
	    childList.forEach(item => {
	      item.check3 = checked
	    })
	    
	    childList = flatList.filter(item => item.id1 === row.id1 && item.permissionName2 === row.permissionName2)
	    childList.forEach(item => {
	      item.check2 = true
	    })
	    
	    childList = flatList.filter(item => item.permissionName1 === row.permissionName1)
	    childList.forEach(item => {
	      item.check1 = true
	    })
	  
	  
	    if (!checked) {//判断同级别有多少个false
	      let pid1 = row["id1"]
	      let pid2 = row["id2"]
	      let groupList = flatList.filter(s => s["id1"] === pid1 && s["id2"] === pid2 && s["id3"] !== row["id3"] && s["check3"])
	      if (groupList == null || groupList.length === 0) {
	        let parentList = flatList.filter(s => s["id1"] === pid1 && s["id2"] === pid2)
	        console.info(parentList);
	        parentList.forEach(v => {
	          v.check2 = false
	        })
	  
	        let parentSameLevelList = flatList.filter(s => s["id1"] === pid1 && s["id2"] !== pid2 && s["check2"])
	        if (parentSameLevelList == null || parentSameLevelList.length === 0) {
	          let rootList = flatList.filter(s => s["id1"] === pid1)
	          if (rootList != null && rootList.length > 0) {
	            rootList.forEach(item => {
	              item.check1 = false
	            })
	  
	          }
	  
	        }
	      }
	    }
	  },
	  
	  // getSysUserList() {
	  //   this.initMainList2();
	  // },
	  initMainList2(showPage) {
		  this.selectionList2 = [];
		  console.log('selectionList ---------------------',this.selectionList);
		  // console.log('showPage ---------------------',showPage);
	    if (showPage == true) {
	      this.pagingConfig2.current = 1;
		   this.pagingConfig2.pageSize = 999999;
	    }
	    const data = {
	      ...this.queryParam5,
	      page: this.pagingConfig2.current,
	      pageSize: this.pagingConfig2.pageSize
	    };
		// console.log('data ---------------------',data);
	    this.showPage = showPage;
	    this.pagingConfig2.tableLoading = true
	    getUserList(data).then((response) => {
	      const {data, status} = response;
	      this.pagingConfig2.tableLoading = false
	      if (status === 200) {
	        this.showPage = true;
	        this.pagingConfig2.total = data.totalCount;
	  	  
	        this.pagingConfig2.tableData = [...data.data]
	  
	      } else {
	        this.notification().warn({
	          body: data.errMsg
	        });
	      }
	    })
	  },
	  
    ...mapMutations(["ROLEEDITLIST"]),
	// ...mapGetters(["roleEditList"]),
    sexFormatter(data) {
      return data === 1 ? this.$t('users.fields.male') : this.$t('users.fields.female')
    },
    handleQuery(formNames) {
      this.$refs[formNames].validate(valid => {
        if (valid) {
          this.getRoleList();
        } else {
          return false;
        }
      });
    },
    handleReset(formName) {
      console.info(this.$refs[formName])
      this.$refs[formName].resetFields();
      this.handleQuery(formName);
    },
    handlePageSizeChange(pageSize) {
      this.pagingConfig.pageSize = pageSize;
      this.pagingConfig.current = 1;
      this.getRoleList();
    },
    handleCurrentChange(currentChange) {
      this.pagingConfig.current = currentChange;
      this.getRoleList();
    },
	handlePageSizeChange2(pageSize) {
	  this.pagingConfig2.pageSize = pageSize;
	  this.pagingConfig2.current = 1;
	  this.initMainList2(false);
	},
	handleCurrentChange2(currentChange) {
	  this.pagingConfig2.current = currentChange;
	  this.initMainList2(false);
	},

	handleSelectionChange2(val) {
		this.selectionList2 = val;
	},

    handleEdit() {
      const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      // this.ROLELIST(tempArr);
      // localStorage.setItem("roleList", JSON.stringify(this.selectionList));
      // this.$router.push({
      //   name: "userEdit",
      //   query: {operateType: "edit"}
      // });
      this.handleRoleEdit(true, tempArr[0]);
    },
    handleAdd() {
      // const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.USERSLIST([]);
      // localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "userAdd",
        query: {operateType: "add"}
      });
    },
    handleDelete() {
      const {selectionList} = this;
      const params = selectionList.map(item => ({
        id: item.id,
      }));
      console.info(params)
      deleteRoleById(params[0].id).then(result => {
        const {status, data} = result;
        if (status === 200 && data.resultCode === 0) {
          this.notification().success({
            body: '操作成功'
          });
          this.getRoleList();
        } else {
          this.notification().error({
            body: data.errMsg
          });
        }
      })
    },
    handleDeleteCheck() {
      this.messageBox()
          .then(() => {
            this.handleDelete();
          })
          .catch(() => {
            this.notification().warn({
              body: "已经取消删除"
            });
          });
    },
    getRoleList() {
      this.initMainList();
    },
    initMainList(showPage) {
		
      if (!showPage) {
        this.pagingConfig.current = 1;
      }
      const data = {
        ...this.queryParam,
        page: this.pagingConfig.current,
        pageSize: this.pagingConfig.pageSize
      };
      this.showPage = showPage;
      this.pagingConfig.tableLoading = true
      getRoleList(data).then((response) => {
        const {data, status} = response;
        this.pagingConfig.tableLoading = false
        if (status === 200) {
          this.showPage = true;
          this.pagingConfig.total = data.totalCount;
          this.pagingConfig.tableData = [...data.data]
          // this.notification().success({
          //   body: '请求成功',
          //   duration: 1
          // });
        } else {
          this.notification().warn({
            body: data.errMsg
          });
        }
      })
    },
    handleRoleAdd() {
      this.$refs.roleAddRef.handleDialogShow(true, {});
    },
    handleRoleEdit(statusFlag, scope) {
      console.info(scope)
      // const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      // this.ROLELIST (tempArr);
      // localStorage.setItem("roleList", JSON.stringify(this.selectionList));
      this.$refs.roleEditRef.handleDialogShow(true, scope);
    },
    handleRowRoleEdit(statusFlag, scope) {
      console.info(scope)
      // const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      // this.ROLELIST (tempArr);
      // localStorage.setItem("roleList", JSON.stringify(this.selectionList));
      this.$refs.roleEditRef.handleDialogShow(true, scope.row);
    },

	
  }
}
</script>
<style lang="scss" scoped>
#page{
	// width: 100vw;
	// height: 100vh;
	  
	  
	  // background: rgb(24, 25, 35);
	  // display: flex;
	  // align-items: center;
	  // justify-content: center;
}
::v-deep .el-card__header{
	padding: 0 !important;
}
.role-list-container {
  .operation {
    width: 100%;
    // height: 48px;
	margin:10px 0;

    .dsn-button {
      margin: 0 8px 0 0;
    }
  }
  
  .info {
    background-color: #ffffff;
    padding: 16px;
  
    .userFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }
  
    .userForm {
      .el-form-item {
        margin: 0 24px 16px 0;
  
        .el-select {
          width: 320px;
        }
  
        .el-input {
          width: 320px;
        }
      }
    }
  }
  
}

//::v-deep.el-table .cell {
//  font-size: 18px;
//}

//::v-deep .el-form-item__label {
//  font-size: 25px;
//}
//
//::v-deep .el-form-item__content {
//  font-size: 25px;
//}
</style>
