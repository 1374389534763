<template>
  <el-dialog title="编辑角色" :visible.sync="showAddRoleDialog" :width="'35%'" left>
    <el-form :model="roleForm" ref="roleEditFormRef" :inline-message="true" :rules="roleFormRules" label-position="left">
      <el-row>
        <el-col :span="24">
          <el-form-item :label="this.$t('roles.fields.roleName')" prop="roleName" :label-width="'150px'">
            <dsn-input size="medium"
                       v-model.trim="roleForm.roleName"
                       placeholder="请输入角色名称"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item :label="this.$t('roles.fields.defaultRole')" prop="defaultRoleFlag" :label-width="'150px'">
            <!--            <dsn-input size="medium" type="textarea"-->
            <!--                       v-model.trim="roleForm.remark"-->
            <!--                       placeholder="请输入备注"-->
            <!--            />-->
            <el-checkbox v-model="roleForm.defaultRoleFlag" :true-label="1" :false-label="0">默认角色</el-checkbox>
            <!--            <el-radio v-model="roleForm.defaultRoleFlag" :label="0">默认角色</el-radio>-->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item :label="this.$t('roles.fields.remark')" prop="remark" :label-width="'150px'">
            <dsn-input size="medium" type="textarea"
                       v-model.trim="roleForm.remark"
                       placeholder="请输入备注"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="showAddRoleDialog = false">取 消</el-button>
    <el-button type="primary" @click="checkAdd(['roleEditFormRef'])">保 存</el-button>
  </span>
  </el-dialog>
</template>
<script>
import {updateRole} from "@/api/efms/roles/role.api";

export default {
  name: 'roleEdit',
  data() {
    return {
      showAddRoleDialog: false,
      roleForm: {
        id: null,
        roleName: null,
        roleCode: null,
        remark: null,
		defaultRoleFlag:null
      }
    }
  },
  created() {
    if (this.$refs['roleEditFormRef'] != null) {
      this.$refs['roleEditFormRef'].resetFields();
    }
  },
  computed:{
    roleFormRules: function () {
      let that = this
      return {
        roleName: [{required: true, message: "请输入角色名称", trigger: "change"}],
      }
    }
  },
  methods: {
    handleDialogShow(showStatus, data) {
      this.roleForm = data;
      this.showAddRoleDialog = showStatus;
    }
    ,
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      console.info(count)
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      let postData = {
        id: this.roleForm.id,
        roleName: this.roleForm.roleName,
        roleCode: this.roleForm.roleCode,
        remark: this.roleForm.remark,
		defaultRoleFlag:this.roleForm.defaultRoleFlag
      }
      console.info(postData)
      updateRole(postData).then(res => {
        const {status, data} = res
        if (status === 200) {
          this.showAddRoleDialog = false;
          this.$refs['roleEditFormRef'].resetFields();
          this.$emit('update-role-success')
          this.notification().success({
            body: "更新成功",
          });
        }else {
          this.notification().error({
            body: `${data.errMsg}`,
          });
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
</style>
